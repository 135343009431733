import { create as createFedopsLogger } from '@wix/fedops-logger';
import { getIsInEditorX } from './isInEditorX';
export var Interactions;
(function (Interactions) {
    Interactions["EditorReady"] = "EditorReady";
    Interactions["InstallApp"] = "InstallApp";
    Interactions["RemoveApp"] = "RemoveApp";
    Interactions["SearchBoxAdded"] = "SearchBoxAdded";
})(Interactions || (Interactions = {}));
export var fedopsLogger = createFedopsLogger('search-editor-platform', {
    customParams: { isInEditorX: getIsInEditorX() },
});
