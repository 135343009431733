var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { getControllerByType, getControllersByType, findControllerByType, } from '@wix/platform-editor-helpers';
import { getSearchAppControllerComponentDefinition } from '../componentDefinitions';
import { ControllerType, WixCodeRole } from '../types';
import { addBreadcrumb } from '../sentry';
import { getIsInEditorX } from '../isInEditorX';
export function connectControllerWithSearchBox(editor, controllerRef, searchBoxRef) {
    return __awaiter(this, void 0, Promise, function () {
        var ex_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    addBreadcrumb('connectControllerWithSearchBox: start');
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, editor.SDK.controllers.connect(editor.token, {
                            controllerRef: controllerRef,
                            connectToRef: searchBoxRef,
                            role: WixCodeRole.SearchBox,
                        })];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    ex_1 = _a.sent();
                    console.error('error in editor.SDK.controllers.connect()', ex_1);
                    return [3 /*break*/, 4];
                case 4:
                    addBreadcrumb('connectControllerWithSearchBox: finish');
                    return [2 /*return*/];
            }
        });
    });
}
export function connectControllerWithSearchResults(editor, controllerRef, searchResultsRef) {
    return __awaiter(this, void 0, Promise, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    addBreadcrumb('connectControllerWithSearchResults: start');
                    return [4 /*yield*/, editor.SDK.controllers.connect(editor.token, {
                            controllerRef: controllerRef,
                            connectToRef: searchResultsRef,
                            role: WixCodeRole.SearchResults,
                        })];
                case 1:
                    _a.sent();
                    addBreadcrumb('connectControllerWithSearchResults: finish');
                    return [2 /*return*/];
            }
        });
    });
}
export function addSearchAppController(editor, pageRef) {
    return __awaiter(this, void 0, Promise, function () {
        var result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    addBreadcrumb('addSearchAppController: start');
                    if (!!pageRef) return [3 /*break*/, 2];
                    return [4 /*yield*/, editor.SDK.siteSegments.getHeader()];
                case 1:
                    // NOTE: use header as master page ref, to add controller to all pages.
                    // we could not use direct `masterPage` ref, because it will brake logic with popups
                    pageRef = _a.sent();
                    _a.label = 2;
                case 2: return [4 /*yield*/, editor.SDK.components.add(editor.token, {
                        componentDefinition: getSearchAppControllerComponentDefinition({
                            appDefinitionId: editor.appDefinitionId,
                        }),
                        pageRef: pageRef,
                    })];
                case 3:
                    result = _a.sent();
                    addBreadcrumb('addSearchAppController: finish');
                    return [2 /*return*/, result];
            }
        });
    });
}
function getSearchAppControllerClassic(editor, pageRef, componentRef) {
    return getControllerByType(editor, ControllerType.SearchApp, pageRef);
}
function getSearchAppControllerEditorX(editor, pageRef, componentRef) {
    return __awaiter(this, void 0, Promise, function () {
        var connectableControllers, ex_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, editor.SDK.document.controllers.listConnectableControllers(editor.token, { componentRef: componentRef })];
                case 1:
                    connectableControllers = _a.sent();
                    return [2 /*return*/, connectableControllers.length
                            ? connectableControllers[0].controllerRef
                            : undefined];
                case 2:
                    ex_2 = _a.sent();
                    console.log('Error while calling SDK.document.controllers.listConnectableControllers', ex_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    });
}
export function getSearchAppControllers(editor) {
    return __awaiter(this, void 0, void 0, function () {
        var results;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    addBreadcrumb('getSearchAppControllers: start');
                    return [4 /*yield*/, getControllersByType(editor, ControllerType.SearchApp)];
                case 1:
                    results = _a.sent();
                    addBreadcrumb('getSearchAppControllers: finish');
                    return [2 /*return*/, results];
            }
        });
    });
}
export function isComponentConnected(editor, componentRef) {
    return __awaiter(this, void 0, Promise, function () {
        var connections, controllers, controllerRef;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    addBreadcrumb('isComponentConnected: start');
                    return [4 /*yield*/, editor.SDK.document.controllers.listConnections(editor.token, { componentRef: componentRef })];
                case 1:
                    connections = _a.sent();
                    controllers = connections.map(function (connection) { return connection.controllerRef; });
                    return [4 /*yield*/, findControllerByType(editor, controllers, ControllerType.SearchApp)];
                case 2:
                    controllerRef = _a.sent();
                    addBreadcrumb('isComponentConnected: finish');
                    return [2 /*return*/, !!controllerRef];
            }
        });
    });
}
export function connectSearchBox(editor, componentRef) {
    return __awaiter(this, void 0, void 0, function () {
        var pageRef, getSearchAppController, controllerRef;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    addBreadcrumb('connectSearchBox: start');
                    return [4 /*yield*/, editor.SDK.components.getPage(editor.token, {
                            componentRef: componentRef,
                        })];
                case 1:
                    pageRef = _a.sent();
                    getSearchAppController = getIsInEditorX()
                        ? getSearchAppControllerEditorX
                        : getSearchAppControllerClassic;
                    return [4 /*yield*/, getSearchAppController(editor, pageRef, componentRef)];
                case 2:
                    controllerRef = _a.sent();
                    if (!!controllerRef) return [3 /*break*/, 4];
                    return [4 /*yield*/, addSearchAppController(editor, pageRef)];
                case 3:
                    controllerRef = _a.sent();
                    _a.label = 4;
                case 4: return [4 /*yield*/, connectControllerWithSearchBox(editor, controllerRef, componentRef)];
                case 5:
                    _a.sent();
                    addBreadcrumb('connectSearchBox: finish');
                    return [2 /*return*/];
            }
        });
    });
}
